import { VITE_HOST, API_URL } from "../config";

export const FREE_USER_DOMAIN = "app.myaltimate.com";
export const FREE_MEGATENANT = "freemegatenant.demo.tryaltimate.com";

export const getTenant = () => {
  const myDomain = window.location.host;
  if (!myDomain.includes("." + VITE_HOST)) return "";
  return myDomain.replace("." + VITE_HOST, "");
};

const CODE_QUERY = "codeQuery";
const LINEAGE = "lineage";
const FREE_STACK = "freeStack";
const WAREHOUSE = "warehouse";
const IS_INSTANCE = "isInstance";
const SHOW_PREMIUM_DATAPILOT = "showPremiumDatapilot";
const SHOW_ENTERPRISE_DATA_DISCOVERY = "showEnterpriseDataDiscovery";
const SHOW_ENTERPRISE_EDITION_SUGGESTION = "showEnterpriseEditionSuggestion";
const JOBS = "jobs";
const SSO_ENABLED = "ssoEnabled";

const featureFlags = {
  [CODE_QUERY]: getTenant() !== "silq",
  [JOBS]: !["shs", "block", "dat", "rampdata", "cisco", "qaebates"].includes(getTenant()),
  [LINEAGE]: getTenant() !== "silq",
  [WAREHOUSE]: [
    "heyday",
    "rakuten",
    "asapp",
    "hydy",
    "shs",
    "block",
    "dat",
    "rampdata",
    "cisco",
    "qaebates",
    "megatenant",
  ].includes(getTenant()),
  [FREE_STACK]:
    window.location.hostname.includes(FREE_USER_DOMAIN) ||
    window.location.hostname.includes(FREE_MEGATENANT),
  [IS_INSTANCE]: window.location.hostname.includes("localhost")
    ? window.location.hostname !== VITE_HOST?.split(":")[0]
    : window.location.hostname !== VITE_HOST,
  [SHOW_PREMIUM_DATAPILOT]: getTenant() === "eastman",
  [SSO_ENABLED]: ["freemegatenant", "msmg", "cisco", "eastman"].includes(
    getTenant()
  ),
  [SHOW_ENTERPRISE_DATA_DISCOVERY]: getTenant() === "msmg",
  [SHOW_ENTERPRISE_EDITION_SUGGESTION]: getTenant() !== "msmg",
};

export const isCodeQuery = () => featureFlags[CODE_QUERY];
export const isLineage = () => featureFlags[LINEAGE];
export const isFreeStack = () => featureFlags[FREE_STACK];
export const showPremiumDatapilot = () => featureFlags[SHOW_PREMIUM_DATAPILOT];
export const showJobs = () => featureFlags[JOBS];
export const isInstanceStack = () => featureFlags[IS_INSTANCE];
export const isWarehouse = () => featureFlags[WAREHOUSE];
export const getDefaultRoute = (tenant: string) =>
  tenant === "silq" ? "/datasets" : "/summary";
export const getBackendUrl = () => {
  return API_URL;
};
export const isSSOEnabled = () => {
  return featureFlags[SSO_ENABLED];
};
export const showEnterpriseDataDiscovery = () =>
  featureFlags[SHOW_ENTERPRISE_DATA_DISCOVERY];
export const showEnterpriseEditionSuggestion = () =>
  featureFlags[SHOW_ENTERPRISE_EDITION_SUGGESTION];
