import { ArrowDown, UserGroupIcon } from "@/assets/icons";
import { Dropdown } from "@/components/form";
import { setUserGroupHeader } from "@/helpers/api_helper";
import { setUserGroup } from "@/modules/app/appSlice";
import { useAppDispatch, useAppState } from "@/modules/app/useAppContext";
import { Button } from "@/uiCore/components/button/Button";
import { useEffect, useMemo, useState } from "react";
import { Alert } from "reactstrap";
import useUserGroups from "./useUserGroups";
import { useQueryClient } from "react-query";
import { isOwner } from "@/services/auth";

const LocalStorageKey = "userGroupId";
const UserGroupSelector = () => {
  const { rbacEnabled, userGroup } = useAppState();
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const [refresh, setRefresh] = useState(Date.now());

  const { groups, isFetching } = useUserGroups();

  const options = (isOwner() ? [{ label: "Default", value: "-1" }] : []).concat(
    groups.map((item) => ({
      label: item.name,
      value: item.id,
    }))
  );

  const selectedUserGroup = useMemo(() => {
    if (userGroup) {
      return options.find((group) => group.value === userGroup.id);
    }

    const lsValue = localStorage.getItem(LocalStorageKey);
    if (!lsValue) {
      if (options.length === 1) {
        return options[0];
      }
      return null;
    }
    return options.find((group) => group.value === lsValue);
  }, [userGroup, options, refresh]);

  // If RBAC is enabled and team is not set, set the first team
  useEffect(() => {
    if (!rbacEnabled || selectedUserGroup || !options.length) {
      return;
    }

    // On page load, set team from local storage
    const storedUserGroupId = localStorage.getItem(LocalStorageKey);
    updateUserGroup(storedUserGroupId || options[0].value);
  }, [rbacEnabled, options, selectedUserGroup]);

  useEffect(() => {
    // Set team header for future sessions
    if (!selectedUserGroup?.value) {
      return;
    }
    setUserGroupHeader(selectedUserGroup.value);
    queryClient.invalidateQueries({
      predicate: (query) => query.queryKey?.[0] !== "UserGroups",
    });
  }, [selectedUserGroup]);

  const currentUserGroup = useMemo(() => {
    if (!options.length || !selectedUserGroup?.value) {
      return null;
    }
    return options.find((item) => item.value === selectedUserGroup.value);
  }, [options, selectedUserGroup?.value]);

  const updateUserGroup = (value: string) => {
    const selectedUserGroup = groups.find((item) => item.id === value);
    dispatch(setUserGroup(selectedUserGroup));
  };

  const onUserGroupSelect = (value: string) => {
    localStorage.setItem(LocalStorageKey, value);
    updateUserGroup(value);
    setRefresh(Date.now());
  };

  if (!rbacEnabled || isFetching) {
    return null;
  }

  if (!options.length) {
    return (
      <Alert color="danger" className="border-0 mt-3">
        You are not part of any team. Please contact your AltimateAI
        administrator to get access.
      </Alert>
    );
  }

  return (
    <Dropdown
      showDivider
      id="user-group-selector"
      value={selectedUserGroup?.value}
      options={options}
      onChange={onUserGroupSelect}
    >
      <Button outline size="sm">
        <UserGroupIcon style={{ height: 18, verticalAlign: "text-bottom" }} />{" "}
        {currentUserGroup?.label || "Select Team"}
        <ArrowDown style={{ width: 16 }} />
      </Button>
    </Dropdown>
  );
};

export default UserGroupSelector;
