import { Badge } from "reactstrap";
import styles from "./styles.module.scss";
import { getMoneySavings } from "@/pages/explorer/utils";
import { useAppState } from "@/modules/app/useAppContext";

export const MoneySavingsTag = ({
  savings = {
    min: "0",
    max: "0",
  },
}) => {
  const { currency } = useAppState();
  return (
    <Badge
      label="Money Savings"
      color="success"
      pill
      className={styles.moneySavings}
    >
      {getMoneySavings(savings.min, savings.max, currency)}
    </Badge>
  );
};

export const TimeSavingsTag = ({ savings = "0" }) => {
  return (
    <Badge
      label="Time Savings"
      color="warning"
      pill
      className={styles.timeSavings}
    >
      {savings}
    </Badge>
  );
};

export const CountTag = ({ count = 0 }) => {
  return (
    <Badge label="Count" color="info" pill className={styles.count}>
      Count: {count}
    </Badge>
  );
};

export const LinenumberTag = ({ linenumber }: { linenumber: string }) => {
  return (
    <Badge label="Linenumber" color="info" pill className={styles.linenumber}>
      {linenumber}
    </Badge>
  );
};
