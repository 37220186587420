import { useEffect, useState } from "react";
import {
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Link, useSearchParams } from "react-router-dom";
import { loadUsetifulScript, setUsetifulTags } from "usetiful-sdk";
import { getUser } from "@/services/auth";

interface TourItem {
  label: string;
  path?: string;
  children?: TourItem[];
  id: string;
}

const tourItems: TourItem[] = [
  {
    label: "Overall Product Tour",
    path: "/summary",
    id: "tour-overall",
  },
  {
    label: "Cost & Savings",
    path: "/summary",
    id: "tour-summary-cost-savings",
  },
];

const TourDropdownItem = ({
  item,
  level = 0,
}: {
  item: TourItem;
  level?: number;
}) => {
  if (item.children) {
    return (
      <>
        <DropdownItem
          header
          style={{ paddingLeft: `${level * 20}px` }}
          id={item.id}
        >
          {item.label}
        </DropdownItem>
        {item.children.map((child, index) => (
          <TourDropdownItem key={child.id} item={child} level={level + 1} />
        ))}
      </>
    );
  }

  return (
    <DropdownItem
      tag={item.path ? Link : "button"}
      to={item.path}
      style={{ paddingLeft: `${level * 20}px` }}
      id={item.id}
    >
      {item.label}
    </DropdownItem>
  );
};

const ProductTour = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const [searchParams] = useSearchParams();
  const showTour =
    searchParams.get("tour") === "true" ||
    localStorage.getItem("tour") === "true";
  const user = getUser();

  useEffect(() => {
    if (showTour && user) {
      setUsetifulTags({
        userId: user.id,
        firstName: user.first_name,
        lastName: user.last_name,
      });
    }
  }, [showTour, user]);

  useEffect(() => {
    if (showTour) {
      localStorage.setItem("tour", "true");
      loadUsetifulScript("437bd2ad93b0d73bf6779ba6c34c2cd9"); // This contains your unique token key
    }
  }, [showTour]);

  if (!showTour) return null;

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle
        tag={Button}
        outline
        color="primary"
        size="sm"
        id="start-product-tour"
      >
        Start Product Tour
      </DropdownToggle>
      <DropdownMenu className="px-2">
        {tourItems.map((item, index) => (
          <TourDropdownItem key={index} item={item} />
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

export default ProductTour;
