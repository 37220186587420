import { Link, useLocation } from "react-router-dom";
import classnames from "classnames";
import styles from "./styles.module.scss";
import text_logo from "../../assets/images/text_logo.svg";
import rocket from "../../assets/icons/rocket.svg";
import datasets from "../../assets/icons/datasets.svg";
import infra from "../../assets/icons/infra.svg";
import settings from "../../assets/icons/settings.svg";
import discover from "../../assets/icons/discover.svg";
import code from "../../assets/icons/code2.svg";
import copilot from "../../assets/icons/copilot.svg";
import colab from "../../assets/icons/collab_icon.svg";
import document from "../../assets/icons/document.svg";
import summary from "../../assets/icons/summary.svg";
import security from "../../assets/icons/security.svg";
import governance from "../../assets/icons/governance.svg";
import logo from "../../assets/images/logo.svg";
import { ArrowDownIcon } from "@assets/icons";
import { default as InviteUser } from "../../assets/icons/invite_user.svg?react";
import { Collapse } from "reactstrap";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import {
  getTenant,
  isCodeQuery,
  isFreeStack,
  isWarehouse,
} from "../../helpers/tenant";
import { BetaTag } from "../../components/tags";
import { useIsEnterpriseUser } from "../../helpers/hooks";
import { getUser, isAdmin, isOwner } from "../../services/auth";
import { PermissionsEnum } from "../../helpers/constants";
import { Button } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppState } from "@/modules/app/useAppContext";
import { setSidebarExpanded } from "@/modules/app/appSlice";
import { handleReadmeClick } from "./helper";
import { ReleaseNotesIcon } from "@/assets/icons";
import classNames from "classnames";
import {
  CollapseLeftIcon,
  ExpandRightIcon,
  InviteUserCollapsedIcon,
} from "@/assets/icons";
import { Tooltip } from "@/uiCore";

const linkClass = classnames(
  "p-2 ps-3 mt-1 ms-3 me-3 d-flex align-items-center",
  styles.nav_link
);

const NormalItem = ({
  label,
  icon,
  hideIcon = false,
  isBeta = false,
  expanded = true,
}: {
  label: string;
  icon?: string;
  hideIcon?: boolean;
  isBeta?: boolean;
  expanded?: boolean;
}) => {
  return (
    <div className="w-100 d-flex justify-content-between align-items-center">
      <div className="d-flex align-items-center justify-content-between">
        <img
          src={icon}
          alt={label}
          className={classnames("ms-n2 me-2", { invisible: hideIcon })}
        />
        {expanded && <div>{label}</div>}
      </div>
      {isBeta && expanded && <BetaTag />}
    </div>
  );
};

const LinkItem = ({
  to,
  label,
  icon,
  hideIcon = false,
  isBeta = false,
  expanded = true,
}: {
  to: string;
  label: string;
  icon?: string;
  hideIcon?: boolean;
  isBeta?: boolean;
  expanded?: boolean;
}) => {
  const { pathname } = useLocation();
  const isSelected = () => {
    if (to?.startsWith("/summary")) {
      return pathname.startsWith("/summary");
    }

    if (to?.startsWith("/discover")) {
      return pathname.startsWith("/discover");
    }

    return pathname === to;
  };
  return (
    <Link
      to={to}
      className={classnames(linkClass, {
        [styles.selected]: isSelected(),
      })}
      id={label}
    >
      <NormalItem
        label={label}
        icon={icon}
        hideIcon={hideIcon}
        isBeta={isBeta}
        expanded={expanded}
      />
    </Link>
  );
};

const CustomNavBox = ({
  to,
  label,
  icon,
  expanded,
  show,
  setShow,
  collapseItems,
  linkBox = true,
}: {
  to: string | null;
  label: string;
  icon: string;
  expanded: boolean;
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  collapseItems: {
    to: string;
    label: string;
    icon?: string;
    hideIcon: boolean;
  }[];
  linkBox?: boolean;
}) => (
  <div>
    <div className={styles.datasets_nav_link}>
      {linkBox && to ? (
        <LinkItem to={to} label={label} icon={icon} expanded={expanded} />
      ) : (
        <div className={linkClass}>
          <NormalItem label={label} icon={icon} expanded={expanded} />
        </div>
      )}
      {expanded && (
        <div
          onClick={(e) => {
            e.preventDefault();
            setShow((b) => !b);
          }}
          className={classNames(styles.nav_link, styles.expandIcon)}
        >
          <ArrowDownIcon className={styles.settings_expand} />
        </div>
      )}
    </div>
    <Collapse isOpen={show && expanded}>
      {collapseItems.map((item, index) => (
        <LinkItem
          key={index}
          to={item.to}
          label={item.label}
          icon={item.icon}
          hideIcon={item.hideIcon}
        />
      ))}
    </Collapse>
  </div>
);

const InviteBox = ({ expanded }: { expanded: boolean }) => {
  const navigate = useNavigate();

  const handleInvite = () => {
    navigate("/settings/users", { state: { invite: true } });
  };

  if (!expanded) {
    return (
      <InviteUserCollapsedIcon
        className={"d-flex align-items-center w-100"}
        onClick={handleInvite}
      />
    );
  }

  return (
    <div className={styles.invite_box}>
      <InviteUser />
      <div className={styles.title}>Invite User</div>
      <div className={styles.text}>
        Invite your team members to collaborate and accelerate the work together
      </div>
      <Button className={styles.invite_button} onClick={handleInvite}>
        Invite
      </Button>
    </div>
  );
};

const SidebarContent = ({ isOverlay = false }) => {
  const [showSettings, setShowSettings] = useState(false);
  const [showDatasets, setShowDatasets] = useState(false);
  const [showInfra, setShowInfra] = useState(false);
  const [showCode, setShowCode] = useState(false);
  const [showGovernance, setShowGovernance] = useState(false);
  const [showCollab, setShowCollab] = useState(false);
  const tenant = getTenant();
  const { sidebarExpanded: expanded, rbacEnabled } = useAppState();
  const dispatch = useAppDispatch();
  const user = getUser();
  const isFreeUser = isFreeStack();
  const navigate = useNavigate();
  const { isEnterpriseUser } = useIsEnterpriseUser();
  const showUserGroupsLink = rbacEnabled;
  const showUsersPage = rbacEnabled ? isAdmin() || isOwner() : true;
  const isExpandedOrOverlayed = expanded || isOverlay;
  return (
    <div
      className={classnames("bg-white", {
        [styles.sidebar_container]: !isOverlay,
        [styles.sidebar_overlay]: isOverlay,
        [styles.expanded]: expanded,
      })}
    >
      <div
        className={classnames(
          styles.logo,
          isExpandedOrOverlayed
            ? "justify-content-between ms-2"
            : "justify-content-center"
        )}
      >
        <img src={isExpandedOrOverlayed ? text_logo : logo} alt="Altimate AI" />
        {isExpandedOrOverlayed && (
          <div
            id="collapse-sidebar"
            onClick={() => {
              dispatch(setSidebarExpanded(expanded ? false : true));
              setShowSettings(false);
            }}
            className={styles.collapseIcon}
          >
            {expanded ? (
              <Tooltip content="Collapse">
                <CollapseLeftIcon />
              </Tooltip>
            ) : (
              <Tooltip content="Expand">
                <ExpandRightIcon />
              </Tooltip>
            )}
          </div>
        )}
      </div>
      <div className="d-flex flex-column spacer">
        {isFreeUser ? (
          <>
            <LinkItem
              to="/quickstart"
              label="Quickstart"
              icon={rocket}
              expanded={isExpandedOrOverlayed}
            />
            <LinkItem
              to="/copilot"
              label="DataPilot"
              icon={copilot}
              expanded={isExpandedOrOverlayed}
            />
            <CustomNavBox
              to="/collaboration/dbt-docs"
              label="Collab"
              icon={colab}
              expanded={isExpandedOrOverlayed}
              show={showCollab}
              setShow={setShowCollab}
              collapseItems={[
                {
                  to: "/collaboration/export-lineage",
                  label: "Export Lineage",
                  icon: settings,
                  hideIcon: true,
                },
              ]}
            />
            <CustomNavBox
              to={null}
              label="Code"
              icon={code}
              expanded={isExpandedOrOverlayed}
              show={showCode}
              setShow={setShowCode}
              linkBox={false}
              collapseItems={[
                {
                  to: "/dbt_models",
                  label: "dbt",
                  hideIcon: true,
                },
              ]}
            />
            <CustomNavBox
              to="/governance/dbtconfig"
              label="Governance"
              icon={governance}
              expanded={isExpandedOrOverlayed}
              show={showGovernance}
              setShow={setShowGovernance}
              collapseItems={[
                {
                  to: "/governance/dbtconfig",
                  label: "dbt Governance",
                  icon: settings,
                  hideIcon: true,
                },
              ]}
            />
            <div
              className={classnames("justify-content-between", linkClass)}
              onClick={(e) => {
                e.preventDefault();
                setShowSettings((b) => !b);
              }}
            >
              <div className="d-flex align-items-center">
                <img src={settings} alt="Settings" className="ms-n2 me-2" />
                {isExpandedOrOverlayed && <div>Settings</div>}
              </div>
              {isExpandedOrOverlayed && (
                <ArrowDownIcon className={styles.settings_expand} />
              )}
            </div>
            <Collapse isOpen={showSettings}>

              <LinkItem
                to="/settings/api-key"
                label="API Key"
                icon={settings}
                hideIcon
              />
              <LinkItem
                to="/settings/credits"
                label="Credits"
                icon={settings}
                hideIcon
              />
              {showUsersPage ? (
                <LinkItem
                  to="/settings/users"
                  label="Users"
                  icon={settings}
                  hideIcon
                />
              ) : null}
              {showUserGroupsLink ? (
                <LinkItem
                  to="/usergroups"
                  label="Teams"
                  icon={settings}
                  hideIcon
                />
              ) : null}
              <LinkItem
                to="/settings/integrations"
                label="Integrations"
                icon={settings}
                hideIcon
              />
              {
                <LinkItem
                  to="/teammates"
                  label="Teammates"
                  icon={settings}
                  hideIcon
                />
              }
            </Collapse>
          </>
        ) : (
          <>
            {tenant !== "silq" && (
              <LinkItem
                to="/summary"
                label="Summary"
                icon={summary}
                expanded={isExpandedOrOverlayed}
              />
            )}
            <LinkItem
              to="/discover?status=active"
              label="Discover"
              icon={discover}
              expanded={isExpandedOrOverlayed}
            />
            <CustomNavBox
              to="/datasets"
              label="Datasets"
              icon={datasets}
              expanded={isExpandedOrOverlayed}
              show={showDatasets}
              setShow={setShowDatasets}
              collapseItems={[
                {
                  to: "/tables",
                  label: "Usage",
                  icon: settings,
                  hideIcon: true,
                },
              ]}
            />

            {isCodeQuery() && (
              <LinkItem
                to="/query/all"
                label="Code"
                icon={code}
                expanded={isExpandedOrOverlayed}
              />
            )}
            {isWarehouse() && (
              <CustomNavBox
                to="/warehouse"
                label="Infra"
                icon={infra}
                expanded={isExpandedOrOverlayed}
                show={showInfra}
                setShow={setShowInfra}
                collapseItems={[
                  {
                    to: "/users",
                    label: "Users & Roles",
                    icon: settings,
                    hideIcon: true,
                  },
                ]}
              />
            )}
            {/* <LinkItem
              to="/contracts"
              label="Contract"
              icon={contract}
              expanded={isExpandedOrOverlayed}
            /> */}
            <LinkItem
              to="/copilot"
              label="DataPilot"
              icon={copilot}
              expanded={isExpandedOrOverlayed}
            />
            <div
              className={classnames("justify-content-between", linkClass)}
              onClick={(e) => {
                e.preventDefault();
                setShowSettings((b) => !b);
              }}
            >
              <div className="d-flex align-items-center">
                <img src={settings} alt="Settings" className="ms-n2 me-2" />
                {isExpandedOrOverlayed && <div>Settings</div>}
              </div>
              {isExpandedOrOverlayed && (
                <ArrowDownIcon className={styles.settings_expand} />
              )}
            </div>

            <Collapse isOpen={showSettings}>
              {!tenant ? (
                <LinkItem
                  to="/settings/companies"
                  label="Companies"
                  icon={settings}
                  hideIcon
                />
              ) : (
                <>
                  {isOwner() && (
                    <LinkItem
                      to="/settings/datastores"
                      label="DataStores"
                      icon={settings}
                      hideIcon
                    />
                  )}
                  {showUsersPage ? (
                    <LinkItem

                      to="/settings/users"
                      label="Users"
                      icon={settings}
                      hideIcon
                    />
                  ) : null}
                  {showUserGroupsLink ? (
                    <LinkItem
                      to="/usergroups"
                      label="Teams"
                      icon={settings}
                      hideIcon
                    />
                  ) : null}
                  <LinkItem
                    to="/settings/api-key"
                    label="API Key"
                    icon={settings}
                    hideIcon
                  />
                  {isFreeUser && (
                    <LinkItem
                      to="/settings/integrations"
                      label="Integrations"
                      icon={settings}
                      hideIcon
                    />
                  )}
                  <LinkItem
                    to="/settings/notification"
                    label="Notifications"
                    icon={settings}
                    hideIcon
                  />
                  {(tenant === "cisco" ||
                    tenant === "megatenant" ||
                    isFreeUser) && ( // TODO: Fix using Proper feature flags
                    <LinkItem
                      to="/teammates"
                      label="Teammates"
                      icon={settings}
                      hideIcon
                    />
                  )}
                  {isOwner() &&
                    user?.permission.includes(PermissionsEnum.SECURITY_VIEW) &&
                    !isFreeUser && (
                      <LinkItem
                        to="/settings/security"
                        label="Security"
                        icon={settings}
                        hideIcon

                      />
                    )}
                </>
              )}
            </Collapse>
          </>
        )}
        <div className="spacer" />
        <div className={styles.sidebar_bottom}>
          <InviteBox expanded={isExpandedOrOverlayed} />
          {!isFreeUser && ( // Temporary change until the documentation is ready
            <>
              <div className="spacer" />
              <div className={styles.divider} />
              <div
                className={classnames(linkClass)}
                onClick={(e) => handleReadmeClick(e, "release")}
              >
                <div className="d-flex align-items-center">
                  <ReleaseNotesIcon className="ms-n2 me-2" />
                  {isExpandedOrOverlayed && <div>Release Notes</div>}
                </div>
              </div>
              <div
                className={classnames(linkClass)}
                onClick={(e) => handleReadmeClick(e, "documentation")}
              >
                <div className="d-flex align-items-center">
                  <img
                    src={document}
                    alt="Documentation"
                    className="ms-n2 me-2"
                  />
                  {isExpandedOrOverlayed && <div>Documentation</div>}
                </div>
              </div>
            </>
          )}

          {/* Documentation Link */}
          {isFreeUser && ( // Show both links only for free users
            <>
              {isExpandedOrOverlayed && !isEnterpriseUser && (
                <div className={styles.buy_subscription}>
                  <Button
                    className={styles.invite_button}
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate("/settings/credits?manage_credits=true");
                    }}
                  >
                    Upgrade Subscription
                  </Button>
                </div>
              )}
              <div className="spacer" />
              <div className={styles.divider} />

              {/* Documentation Link */}
              <div
                className={classnames("mb-2", linkClass)}
                onClick={(e) => {
                  e.stopPropagation();
                  window.open("https://docs.myaltimate.com", "_blank");
                }}
              >
                <div className="d-flex align-items-center">
                  <img
                    src={document}
                    alt="Documentation"
                    className="ms-n2 me-2"
                  />
                  {isExpandedOrOverlayed && <div>Documentation</div>}
                </div>
              </div>

              {/* Security FAQ Link */}
              <div
                className={classnames("mb-2", linkClass)}
                onClick={(e) => {
                  e.stopPropagation();
                  window.open("https://docs.myaltimate.com/arch/faq", "_blank");
                }}
              >
                <div className="d-flex align-items-center">
                  <img
                    src={security}
                    alt="Security FAQ"
                    className="ms-n2 me-2"
                  />
                  {isExpandedOrOverlayed && <div>Security FAQ</div>}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const Sidebar = () => {
  const { sidebarExpanded: expanded } = useAppState();
  const [isHovering, setIsHovering] = useState(false);
  const collapseTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (!expanded) {
      // Prevent hover from triggering right after collapsing
      collapseTimeoutRef.current = setTimeout(() => {
        collapseTimeoutRef.current = null;
      }, 300);
    }

    return () => {
      if (collapseTimeoutRef.current) {
        clearTimeout(collapseTimeoutRef.current);
      }
    };
  }, [expanded]);

  return (
    <div
      onMouseEnter={() => {
        if (!expanded && !collapseTimeoutRef.current) {
          setIsHovering(true);
        }
      }}
      onMouseLeave={() => setIsHovering(false)}
    >
      <SidebarContent />
      {!expanded && isHovering && <SidebarContent isOverlay />}
    </div>
  );
};

export { Sidebar };
