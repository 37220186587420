import { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import {
  getContracts,
  getIncidents,
  getTableFilter,
  getTables,
  getTablesAccessed,
  getTags,
  getAlertEvents,
} from "../helpers/backend_helper";
import { getTrueKeys, toggleSetValue } from "../helpers/utils";
import useFiltersBySearchParams from "@/helpers/useFiltersBySearchParams";
import _ from "lodash";
import { DEFAULT_PAGE_SIZE } from "@/components/paginate/constants";
import usePaginate from "@/components/paginate/usePaginate";

const GET_CONTRACTS = "GET-CONTRACTS";
export const GET_INCIDENTS = "GET-INCIDENTS";
const GET_TABLE_FILTERS = "GET-TABLE-FILTERS";
const GET_TAGS = "GET-TAGS";
const GET_RECENT_TABLES = "GET-RECENT-TABLES";
export const GET_TABLES = "GET-TABLES";
export const GET_ALERT_NOTIFICATIONS = "GET-ALERT-NOTIFICATIONS";

export const useMultiSelect = (
  onChange,
  defaultValue = new Map(),
  searchParamKey = undefined
) => {
  const {
    searchParams,
    updateSearchParamsByKey,
    deleteSearchParamsByKeyValue,
  } = useFiltersBySearchParams();
  const [map, setMap] = useState(defaultValue);
  const searchParamValue = searchParams.getAll(searchParamKey).join(",");

  useEffect(() => {
    if (searchParamValue) {
      setMap(
        searchParamValue.split(",").reduce((acc, key) => {
          acc.set(key, true);
          return acc;
        }, new Map())
      );
    } else {
      setMap(defaultValue);
    }
  }, [searchParamValue]);

  const updateMap = (mapValues) => {
    if (searchParamKey) {
      if (mapValues.size === 0) {
        deleteSearchParamsByKeyValue({ [searchParamKey]: undefined });
        return;
      }
      updateSearchParamsByKey({
        [searchParamKey]: [...mapValues.keys()],
      });
    }
  };

  const getValue = (key) => !!map.get(key);
  const toggleValue = (key) => {
    onChange();
    setMap((_map) => {
      const isUnchecked = Boolean(_map.get(key));

      const newMap = new Map(_map);
      if (isUnchecked) {
        newMap.delete(key);
      } else {
        newMap.set(key, !_map.get(key));
      }
      updateMap(newMap);
      return newMap;
    });
  };
  const reset = () => {
    onChange();
    setMap(new Map());
    updateMap(new Map());
  };
  const removeFilter = (removeLabel, contractOptions) => {
    onChange();
    const removeKey = contractOptions.find(
      (option) => option.label === removeLabel
    )?.value;
    setMap((prevMap) => {
      const newMap = new Map(prevMap);
      newMap.forEach((_, key) => {
        if (key === removeKey) {
          newMap.delete(key);
        }
      });
      updateMap(newMap);
      return newMap;
    });
  };

  const getTrueValues = () => getTrueKeys(map);

  return { getValue, toggleValue, reset, getTrueValues, removeFilter };
};

export const useSingleSelect = (
  onChange,
  isToggle = false,
  defaultValue = ""
) => {
  const [value, setValue] = useState(defaultValue);
  return [
    value,
    (v) => {
      onChange();
      setValue((_v) => (isToggle && v === _v ? defaultValue : v));
    },
  ];
};

export const useContractFilter = (onChange) => {
  const { data, isLoading } = useQuery({
    queryKey: GET_CONTRACTS,
    queryFn: getContracts,
  });

  const contractFilter = useMultiSelect(onChange);
  const contractOptions = useMemo(() => {
    const contractMap = {};
    const contracts = data?.contracts || [];
    contracts.forEach((c) => {
      if (!contractMap[c.name]) contractMap[c.name] = [];
      contractMap[c.name].push(c.id);
    });
    return Object.entries(contractMap).map(([k, v]) => ({
      label: k,
      value: v,
    }));
  }, [data]);

  return { contractOptions, contractFilter, isLoading };
};

const timeFilterLabels = { "24h": "24 hours", "1w": "1 week" };
const timeFilterOptions = ["24h", "1w"];

const levelFilterLabels = { error: "Error", warning: "Warning" };
const levelFilterOptions = ["error", "warning"];

const makeOptions = (values, labels) =>
  values.map((v) => ({ value: v, label: labels[v] }));

export const useIncidentFilter = ({ tableId, eventId }) => {
  const [page, setPage] = useState(0);
  const resetPage = () => setPage(0);

  const {
    getValue: getLevelValue,
    toggleValue: toggleLevelValue,
    getTrueValues: getLevelFilter,
  } = useMultiSelect(resetPage);
  const { contractFilter, contractOptions } = useContractFilter(resetPage);

  const [timeFilter, setTimeFilter] = useSingleSelect(resetPage, true);

  const [sortAttribute, setSortAttribute] = useState("incident_id");
  const [sort, setSort] = useState({
    incident_id: "",
    policy_name: "",
  });
  const [sortIncident, setIncidentSort] = useState("");
  const [sortPolicy, setPolicySort] = useState("");

  const levelFilter = getLevelFilter();
  const _contractFilter = contractFilter.getTrueValues();

  let { data: incidents, isLoading } = useQuery({
    queryKey: [
      GET_INCIDENTS,
      tableId,
      page + 1,
      sortAttribute,
      sort,
      timeFilter,
      eventId,
      ...levelFilter,
      ..._contractFilter,
    ],
    queryFn: () => {
      const params = { page: page + 1 };
      if (typeof tableId !== "undefined") params.table_id = tableId;
      if (levelFilter.length > 0) params.levels = levelFilter;
      if (timeFilter) params.timestamp = timeFilter;
      if (sortAttribute) params.sortColumn = sortAttribute;
      if (sort[sortAttribute]) params.sortOrder = sort[sortAttribute];
      if (_contractFilter.length > 0) params.contract_ids = _contractFilter;
      if (eventId) params.event_id = eventId;
      return getIncidents(params);
    },
  });

  const onSort = (column) => (key) => {
    setSort((prevSortOrder) => ({
      ...prevSortOrder,
      [column]: key,
    }));
    setSortAttribute(column);
  };

  const isFilter =
    timeFilter || levelFilter.length > 0 || _contractFilter.length > 0;

  const paginate = {
    itemCount: incidents?.total,
    page: page,
    pageSize: incidents?.size,
    numPages: incidents?.pages,
    onPageClick: setPage,
  };

  return {
    isLoading,
    incidents: incidents?.items,
    isFilter,
    paginate,

    sort: {
      onIncident: onSort("incident_id"),
      onPolicy: onSort("policy_name"),
      incidentSortValue: sort.incident_id,
      policySortValue: sort.policy_name,
    },

    timeFilter: {
      options: makeOptions(timeFilterOptions, timeFilterLabels),
      value: timeFilter,
      onChange: setTimeFilter,
      getLabel: () => timeFilterLabels[timeFilter] || "",
    },

    levelFilter: {
      options: makeOptions(levelFilterOptions, levelFilterLabels),
      value: getLevelValue,
      onChange: toggleLevelValue,
      getLabel: () => makeOptions(levelFilter, levelFilterLabels),
    },

    contractFilter,
    contractOptions,
  };
};

const defaultFilter = () => ({
  datasources: new Set(),
  databases: new Set(),
  schemas: new Set(),
});

export const useDatasetFilter = ({ search, defaultFilters = {} }) => {
  const { page, pageSize, setPage, updatePageSize, resetPage } = usePaginate();
  const {
    searchParams,
    updateSearchParamsByKey,
    deleteSearchParamsByKeyValue,
  } = useFiltersBySearchParams();

  useEffect(resetPage, [search]);

  const {
    contractFilter,
    contractOptions,
    isLoading: isContractLoading,
  } = useContractFilter(resetPage);

  const filter = {
    datasources: new Set(searchParams.getAll("datasources")),
    databases: new Set(searchParams.getAll("databases")),
    schemas: new Set(searchParams.getAll("schemas")),
  };

  const setFilter = (value) => {
    updateSearchParamsByKey(
      Object.entries(value).reduce((acc, [key, value]) => {
        acc[key] = [...value];
        return acc;
      }, {})
    );
  };

  // default to snowflake
  const dsType = searchParams.get("datasource_type") || "snowflake";
  const setDSType = (value) => {
    resetPage();
    setFilter(defaultFilter());
    updateSearchParamsByKey({ datasource_type: value });
  };

  const { data: tableFilters, isFetching: isTableFiltersLoading } = useQuery({
    queryKey: GET_TABLE_FILTERS,
    queryFn: getTableFilter,
  });

  const { data: tagOptions, isFetching: isTagOptionsLoading } = useQuery({
    queryKey: GET_TAGS,
    queryFn: getTags,
  });

  const {
    data: recentlyAccessedTables,
    isFetching: isRecentlyAccessedTablesLoading,
  } = useQuery({
    queryKey: GET_RECENT_TABLES,
    queryFn: getTablesAccessed,
  });

  const { tags = [] } = defaultFilters;
  const _tagsDefaultFilterMap = new Map();
  for (const t of tags) _tagsDefaultFilterMap.set(t, true);
  const tagFilter = useMultiSelect(() => {}, _tagsDefaultFilterMap, "insight");
  const _tagFilter = tagFilter.getTrueValues();

  const _contractFilter = contractFilter.getTrueValues();
  const [tables, setTables] = useState(null);
  const { isFetching: isTableLoading } = useQuery({
    queryKey: [
      GET_TABLES,
      page + 1,
      pageSize,
      search,
      dsType,
      ..._contractFilter,
      ..._tagFilter,
      ...filter.datasources,
      ...filter.databases,
      ...filter.schemas,
    ],
    queryFn: () => {
      const params = { page: page + 1, size: pageSize };
      if (search) params.keyword = search;
      if (_contractFilter.length > 0) params.contract_ids = _contractFilter;
      if (_tagFilter.length > 0) params.tags = _tagFilter;
      if (dsType) params.datasource_type = dsType;
      const setParams = (key) => {
        if (filter[key].size > 0) params[key] = [...filter[key]];
      };
      setParams("datasources");
      setParams("databases");
      setParams("schemas");
      return getTables(params);
    },
    onSuccess: (data) => {
      data.items.forEach((t) => {
        const tagsMap = {};
        (t.columns || []).forEach((c) => {
          (c.tags || []).forEach((item) => (tagsMap[item.name] = item));
        });
        if (!t.tags) t.tags = [];
        t.tags.push(...Object.values(tagsMap).flat());
      });
      setTables(data);
    },
  });

  const { datasources, databases, schemas } = useMemo(() => {
    const datasources = [];
    const databases = [];
    const schemas = [];
    if (!dsType || !tableFilters) return { datasources, databases, schemas };
    const _tableFilters = tableFilters[dsType];
    for (let ds in _tableFilters) {
      const dsValue = _tableFilters[ds];
      const dsItem = {
        label: ds,
        value: ds,
        count: Object.keys(dsValue).length,
        checked: filter.datasources.has(ds),
      };
      datasources.push(dsItem);
      if (dsItem.checked) {
        for (let db in dsValue) {
          const dbValue = dsValue[db];
          const dbItem = {
            label: db,
            value: db,
            count: Object.keys(dbValue).length,
            checked: filter.databases.has(db),
          };
          databases.push(dbItem);
          if (dbItem.checked) {
            for (let s in dbValue) {
              const sValue = dbValue[s];
              const sItem = {
                label: s,
                value: s,
                count: sValue,
                checked: filter.schemas.has(s),
              };
              schemas.push(sItem);
            }
          }
        }
      }
    }
    return { datasources, databases, schemas };
  }, [tableFilters, filter, dsType]);

  const isFilter =
    search ||
    filter.datasources.size > 0 ||
    filter.databases.size > 0 ||
    filter.schemas.size > 0 ||
    _contractFilter.length > 0 ||
    _tagFilter.length > 0;
  const isDataset = tables?.total === 0 && !isFilter;

  const onDatasourceChange = (x) => {
    setFilter({
      datasources: toggleSetValue(filter.datasources, x),
      databases: new Set(),
      schemas: new Set(),
    });
    resetPage();
  };
  const onDatabaseChange = (x) => {
    setFilter({
      datasources: filter.datasources,
      databases: toggleSetValue(filter.databases, x),
      schemas: new Set(),
    });
    resetPage();
  };
  const onSchemaChange = (x) => {
    setFilter({
      datasources: filter.datasources,
      databases: filter.databases,
      schemas: toggleSetValue(filter.schemas, x),
    });
    resetPage();
  };

  const resetFilter = () => {
    resetPage();
    contractFilter.reset();
    deleteSearchParamsByKeyValue({
      datasource_type: undefined,
      datasources: undefined,
      databases: undefined,
      schemas: undefined,
      insight: undefined,
    });
  };

  const paginate = {
    itemCount: tables?.total,
    page,
    pageSize,
    showPageSize: true,
    onPageSizeChange: updatePageSize,
    numPages: tables?.pages,
    onPageClick: setPage,
  };

  const isLoading =
    isContractLoading ||
    isTableFiltersLoading ||
    isTagOptionsLoading ||
    isRecentlyAccessedTablesLoading;

  return {
    isDataset,
    tables: tables?.items,
    paginate,
    filter: {
      datasourceType: dsType,
      onDatasourceTypeChange: setDSType,
      datasources,
      onDatasourceChange,
      databases,
      onDatabaseChange,
      schemas,
      onSchemaChange,
      contractOptions: contractOptions.map((item) => ({
        ...item,
        checked: contractFilter.getValue(item.value),
      })),
      onContractFilter: contractFilter.toggleValue,
      tagOptions: tagOptions?.column_badges?.map((item) => ({
        label: item,
        value: item,
        checked: tagFilter.getValue(item),
      })),
      recentlyAccessedTables: recentlyAccessedTables,
      onTagChange: tagFilter.toggleValue,
      resetFilter,
    },
    isLoading,
    isTableLoading,
  };
};

export const useAlertNotificationsFilter = ({ alertId }) => {
  const { page, pageSize, setPage, updatePageSize, resetPage } = usePaginate();

  const [timeFilter, setTimeFilter] = useSingleSelect(resetPage, true);

  const [sort, setSort] = useState({ column: "alert_id", order: "desc" });

  let { data: notifications, isLoading } = useQuery({
    queryKey: [GET_ALERT_NOTIFICATIONS, alertId, page + 1, sort, timeFilter],
    queryFn: () => {
      const params = { page: page + 1, size: pageSize };
      if (typeof alertId !== "undefined") params.alert_id = alertId;
      if (timeFilter) params.timestamp = timeFilter;
      if (sort.column) params.sortColumn = sort.column;
      if (sort.order) params.sortOrder = sort.order;
      return getAlertEvents(params);
    },
  });

  const onSort = (column) => (order) =>
    order === "" ? setSort({}) : setSort({ column, order });

  const isFilter = timeFilter;

  const paginate = {
    itemCount: notifications?.total,
    page: page,
    pageSize: pageSize,
    numPages: notifications?.pages,
    onPageClick: setPage,
    onPageSizeChange: updatePageSize,
    showPageSize: true,
  };

  return {
    isLoading,
    notifications: notifications?.items,
    isFilter,
    paginate,

    sort: {
      onID: onSort("id"),
      onName: onSort("name"),
      onCheckID: onSort("alert_id"),
    },

    timeFilter: {
      options: makeOptions(timeFilterOptions, timeFilterLabels),
      value: timeFilter,
      onChange: setTimeFilter,
      getLabel: () => timeFilterLabels[timeFilter] || "",
    },
  };
};
