import { useEffect } from "react";
import { setSidebarExpanded } from "@/modules/app/appSlice";
import { useAppDispatch } from "@/modules/app/useAppContext";

const SidebarResizeListener = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const handleResize = () => {
      const isSmallScreen = window.innerWidth < 1440;
      dispatch(setSidebarExpanded(!isSmallScreen));
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [dispatch]);

  return null;
};

export default SidebarResizeListener;
