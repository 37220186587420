import {
  forwardRef,
  useImperativeHandle,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useCallback } from "react";
import styles from "./styles.module.scss";
import { debounce } from "lodash";
import { RiSearchLine } from "react-icons/ri";
import classnames from "classnames";
import { uniqueId } from "lodash";
import { BetterPopover } from "../popover";
import { scroll_style } from "../scrollContainer";
import { Button } from "reactstrap";
import { MultiSelect } from "./Select";
import { Input } from "reactstrap";

const styleMap = {
  sm: styles.padding_sm,
  md: styles.padding_md,
  lg: styles.padding_lg,
};

const DebouncedInput = ({
  defaultValue = "",
  onChange,
  placeholder,
  debounceDelay = 500,
  size = "md",
  hideOutline = false,
}) => {
  const [value, setValue] = useState(defaultValue);
  const debounceFn = useCallback(
    debounce(onChange, debounceDelay, { trailing: true }),
    [onChange, debounceDelay]
  );
  return (
    <div
      className={classnames(styles.debounce_input, styleMap[size], {
        [styles.outline]: !hideOutline,
      })}
    >
      <RiSearchLine />
      <input
        placeholder={placeholder}
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
          debounceFn(e.target.value);
        }}
      />
    </div>
  );
};

const SearchInput = ({
  defaultValue = "",
  placeholder = "Search",
  size = "md",
  onChange,
  hideOutline = false,
}) => {
  const [value, setValue] = useState(defaultValue);

  return (
    <div
      className={classnames(styles.debounce_input, styleMap[size], {
        [styles.outline]: !hideOutline,
      })}
    >
      <RiSearchLine />
      <input
        placeholder={placeholder}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onKeyDown={(e) => {
          if (e.key == "Enter") {
            e.stopPropagation();
            onChange(value);
          }
        }}
      />
    </div>
  );
};

const SimpleInput = ({
  defaultValue = "",
  onChange,
  placeholder,
  size = "md",
  hideOutline = false,
}) => {
  const [value, setValue] = useState(defaultValue);

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      onChange(value);
    }
  };

  return (
    <div
      className={classnames(styles.simple_input, styleMap[size], {
        [styles.outline]: !hideOutline,
      })}
    >
      <RiSearchLine
        className={styles.search_icon}
        onClick={() => onChange(value)}
      />
      <input
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
      />
    </div>
  );
};

const ArrayInput = forwardRef(function ArrayInput(props, ref) {
  const { defaultValue = [], placeholder, options, onChange } = props;
  const trggerRef = useRef();
  const id = useMemo(() => uniqueId("array-input"), []);
  const [value, setValue] = useState(() => {
    const _value = {};
    defaultValue.forEach((item) => (_value[item] = true));
    return _value;
  });
  const [dropdownValue, setDropdownValue] = useState(value);
  const [inputValue, setInputValue] = useState("");
  useImperativeHandle(
    ref,
    () => ({
      getValue: () => options.filter((item) => value[item.value]),
    }),
    [options, value]
  );
  const [triggerWidth, setTriggerWidth] = useState(400);
  useLayoutEffect(() => {
    if (!trggerRef.current) return;
    const { width } = trggerRef.current.getBoundingClientRect();
    setTriggerWidth(width);
  }, []);
  return (
    <>
      <div className="d-flex flex-column gap-sm">
        {options
          .filter((item) => value[item.value])
          .map((item) => (
            <div
              key={item.value}
              className={classnames(styles.form_input, styles.form_value)}
            >
              {item.label}
            </div>
          ))}
        <div id={id} ref={trggerRef} className={classnames(styles.form_input)}>
          <input
            className={styles.search_input}
            placeholder={placeholder}
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
          />
          <div className={styles.add_btn}>Add</div>
        </div>
      </div>
      <BetterPopover
        target={id}
        hideArrow
        placement="bottom"
        style={{
          width: `${triggerWidth}px`,
          overflowY: "auto",
        }}
        containerClass="h-100"
      >
        {({ close }) => (
          <div className="p-2 h-100 d-flex flex-column">
            <div style={{ maxHeight: "400px" }} className={scroll_style}>
              <MultiSelect
                options={options.filter((item) =>
                  item.label.toLowerCase().includes(inputValue.toLowerCase())
                )}
                onChange={(key) =>
                  setDropdownValue((prev) => ({ ...prev, [key]: !prev[key] }))
                }
                value={(key) => dropdownValue[key] || false}
              />
            </div>
            <div className="d-flex gap-sm">
              <Button
                size="sm"
                color="primary"
                onClick={() => {
                  close();
                  const newValue = { ...value, ...dropdownValue };
                  setValue(newValue);
                  onChange?.(
                    options
                      .filter((item) => newValue[item.value])
                      .map((item) => item.value)
                  );
                }}
              >
                Apply
              </Button>
              <Button
                size="sm"
                outline
                onClick={() => {
                  close();
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
        )}
      </BetterPopover>
    </>
  );
});

export const ExpandableFormInput = (props) => (
  <Input className={styles.form_input} {...props} />
);

export { DebouncedInput, SimpleInput, ArrayInput, SearchInput };
