import { createContext, useState } from "react";
import { Outlet } from "react-router-dom";
import { IntercomProvider } from "react-use-intercom";
import { RelativeComponentLoader } from "../../components/loader";
import { Header } from "./Header";
import { Sidebar } from "./Sidebar";
import styles from "./styles.module.scss";
import { IntercomChatBoot } from "../intercom/IntercomChat";
import { INTERCOM_APP_ID, ENV } from "../../config";
import { getTenant } from "../../helpers/tenant";
import { useAppState } from "@/modules/app/useAppContext";

export const SearchContext = createContext({
  search: "",
  setSearch: (_text: string) => {},
});

const Home = () => {
  const [search, setSearch] = useState("");
  const tenant = getTenant();
  const { rbacEnabled, userGroupsLoaded } = useAppState();

  const isReady = rbacEnabled ? userGroupsLoaded : true;

  return (
    <SearchContext.Provider value={{ search, setSearch }}>
      <IntercomProvider appId={INTERCOM_APP_ID} autoBoot={false}>
        <IntercomChatBoot tenant={tenant} env={ENV} />
        <div className={styles.layout}>
          <Sidebar />
          <div className={styles.content}>
            <Header />
            <div id="altimate_main" className={styles.main_content}>
              {!isReady ? <RelativeComponentLoader label="Loading..." /> : <Outlet />}
            </div>
          </div>
        </div>
      </IntercomProvider>
    </SearchContext.Provider>
  );
};

export { Home };
