import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button } from "reactstrap";

// Don't panic, this is just a readme password for the docs
const DOCS_PASSWORD = "AltimatePartner41!";

export const handleReadmeClick = async (e, readmeType = "release") => {
  e.stopPropagation();

  try {
    await navigator.clipboard.writeText(DOCS_PASSWORD);

    let userNavigated = false; // Flag to track manual navigation

    let url = "";
    let viewMessage = "";

    switch (readmeType) {
      case "release":
        url = "https://docs.altimate.ai/docs/release-notes";
        viewMessage = "release notes";
        break;
      case "documentation":
        url = "https://docs.altimate.ai/docs";
        viewMessage = "documentation";
        break;
      default:
        url = "https://docs.altimate.ai/docs";
        viewMessage = "documentation";
        break;
    }

    toast.success(
      ({ closeToast }) => (
        <div>
          <h6>Password copied to clipboard!</h6>
          <p>Use the copied password for viewing {viewMessage}.</p>
          <p>
            You will be redirected in 5 seconds.{" "}
            <Button
              onClick={() => {
                userNavigated = true; // Mark as navigated
                window.open(url, "_blank");
                closeToast();
              }}
              className="mt-2"
            >
              Click here to go now
            </Button>
          </p>
        </div>
      ),
      {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      }
    );

    setTimeout(() => {
      if (!userNavigated) {
        window.open(url, "_blank");
      }
    }, 5000);
  } catch (error) {
    toast.error("Failed to copy password!");
    console.error("Clipboard error:", error);
  }
};
