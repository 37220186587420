import { useMemo, useState } from "react";
import { Button } from "reactstrap";
import arrowLeft from "../../assets/icons/arrow_left.svg";
import arrowRight from "../../assets/icons/arrow_right.svg";
import styles from "./styles.module.scss";
import { Dropdown } from "../form";
import { ArrowDown } from "@/assets/icons";
import { Stack } from "@/uiCore";
import { DEFAULT_PAGE_SIZE } from "./constants";

const PAGE_SIZE_OPTIONS = [
  { label: "10", value: 10 },
  { label: "20", value: 20 },
  { label: "30", value: 30 },
  { label: "40", value: 40 },
  { label: "50", value: 50 },
  { label: "80", value: 80 },
  { label: "100", value: 100 },
];

type ShowComponentProps = {
  pageSize: number;
  onPageSizeChange: (v: number) => void;
};

const ShowComponent = ({ pageSize, onPageSizeChange }: ShowComponentProps) => {
  const [pageSizeData, setPageSizeData] = useState(pageSize);

  return (
    <div className="d-flex align-items-center gap-sm">
      <div>Show</div>
      <Dropdown
        id={"paginate-dropdown"}
        onChange={(e: number) => {
          setPageSizeData(e);
          onPageSizeChange(e);
        }}
        value={pageSizeData}
        options={PAGE_SIZE_OPTIONS}
        showDivider
      >
        <div className={styles.dropdown}>
          <div className={styles.label}>{pageSizeData}</div>
          <div className={styles.arrow_down}>
            <ArrowDown />
          </div>
        </div>
      </Dropdown>
      <div>results</div>
    </div>
  );
};

type IndefinitePaginateProps = {
  page: number;
  onPageClick: (v: number) => void;
  onPrevPage: () => void;
  onNextPage: () => void;
  pageKeys: Record<string, string>;
} & ShowComponentProps;

const IndefinitePaginate = ({
  page,
  pageSize = DEFAULT_PAGE_SIZE,
  onPageSizeChange,
  onPageClick,
  onPrevPage,
  onNextPage,
  pageKeys,
}: IndefinitePaginateProps) => {
  return (
    <div className="mt-4 d-flex justify-content-between align-items-center">
      <ShowComponent pageSize={pageSize} onPageSizeChange={onPageSizeChange} />
      <div className="d-flex align-items-center">
        {page >= 1 && (
          <Button
            className={styles.arrowButtonLeft}
            onClick={(e) => {
              e.preventDefault();
              onPrevPage();
            }}
          >
            <img src={arrowLeft} className={styles.arrowIcon} />
          </Button>
        )}
        {Object.keys(pageKeys).map((p) => (
          <Button
            color={page === parseInt(p) ? "primary" : "white"}
            key={p}
            className="ms-1 me-1"
            onClick={(e) => {
              e.preventDefault();
              onPageClick(parseInt(p));
            }}
          >
            {parseInt(p) + 1}
          </Button>
        ))}
        <Button
          className={styles.arrowButtonRight}
          onClick={(e) => {
            e.preventDefault();
            onNextPage();
          }}
        >
          <img src={arrowRight} className={styles.arrowIcon} />
        </Button>
      </div>
    </div>
  );
};

type PaginateProps = {
  itemCount: number;
  page: number;
  numPages: number;
  onPageClick: (v: number) => void;
  showPageSize?: boolean;
} & ShowComponentProps;

const Paginate = ({
  itemCount,
  page,
  pageSize = DEFAULT_PAGE_SIZE,
  onPageSizeChange,
  numPages,
  onPageClick,
  showPageSize = false,
}: PaginateProps) => {
  const pages = useMemo(
    () =>
      Array(numPages)
        .fill(0)
        .map((_, i) => i),
    [numPages]
  );
  // startPage will be the start of the pages to be shown for the tables(except 1 and ...)
  // endPage will be the end of the pages to be shown for the tables(except numPages and ...)
  let startPage = 0;
  let endPage = numPages - 1;

  // Calculate start and end page based on current page and numPages
  if (numPages > 8) {
    if (page < 4) {
      startPage = 0;
      endPage = 6;
    } else if (page >= numPages - 4) {
      startPage = numPages - 7;
      endPage = numPages - 1;
    } else {
      startPage = page - 2;
      endPage = page + 2;
    }
  }

  // Display ellipsis for pagination numbers not shown
  const showEllipsisStart = startPage > 1;
  const showEllipsisEnd = endPage < numPages - 2;

  // TODO: should be handled in actual table, not in pagination
  if (itemCount === 0) {
    return <div>No results</div>;
  }

  return (
    <div className="mt-4 d-flex justify-content-between align-items-center">
      {showPageSize ? (
        <ShowComponent
          pageSize={pageSize}
          onPageSizeChange={onPageSizeChange}
        />
      ) : null}

      <Stack className="align-items-center justify-content-end flex-1">
        <div>
          Showing{" "}
          <span className="fw-bold">
            {page * pageSize + 1}-{Math.min(itemCount, (page + 1) * pageSize)}
          </span>{" "}
          from <span className="fw-bold">{itemCount}</span> results
        </div>
        <div className="d-flex align-items-center">
          {page >= 1 && (
            <Button
              className={styles.arrowButtonLeft}
              onClick={(e) => {
                e.preventDefault();
                onPageClick(page - 1);
              }}
            >
              <img src={arrowLeft} className={styles.arrowIcon} />
            </Button>
          )}
          {showEllipsisStart && (
            <>
              <Button
                color={"white"}
                key={0}
                className="ms-1 me-1"
                onClick={(e) => {
                  e.preventDefault();
                  onPageClick(0);
                }}
              >
                {1}
              </Button>
              <Button
                color={"white"}
                key={"front ..."}
                className="ms-1 me-1"
                onClick={(e) => {
                  e.preventDefault();
                }}
                style={{ pointerEvents: "none" }}
              >
                ...
              </Button>
            </>
          )}
          {pages.slice(startPage, endPage + 1).map((p) => (
            <Button
              color={page === p ? "primary" : "white"}
              key={p}
              className="ms-1 me-1"
              onClick={(e) => {
                e.preventDefault();
                onPageClick(p);
              }}
            >
              {p + 1}
            </Button>
          ))}
          {showEllipsisEnd && (
            <>
              <Button
                color={"white"}
                key={"back ..."}
                className="ms-1 me-1"
                onClick={(e) => {
                  e.preventDefault();
                }}
                style={{ pointerEvents: "none" }}
              >
                ...
              </Button>
              <Button
                color={"white"}
                key={0}
                className="ms-1 me-1"
                onClick={(e) => {
                  e.preventDefault();
                  onPageClick(numPages - 1);
                }}
              >
                {numPages}
              </Button>
            </>
          )}
          {page < numPages - 1 && (
            <Button
              className={styles.arrowButtonRight}
              onClick={(e) => {
                e.preventDefault();
                onPageClick(page + 1);
              }}
            >
              <img src={arrowRight} className={styles.arrowIcon} />
            </Button>
          )}
        </div>
      </Stack>
    </div>
  );
};

export { Paginate, IndefinitePaginate };
